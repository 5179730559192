import { graphql } from 'gatsby'
import React from 'react'

import devFeatureToggle from '../../devFeatureToggle.json'
import { NpmTabloidsPage } from '../local_modules'

import type { TabloidNbbType } from '../local_modules/types/Tabloids'
import type { PageProps } from 'gatsby'

interface TabloidSection {
  cmsUrlTabloidNbb?: {
    sections?: {
      data?: TabloidNbbType
    }[]
  }
}

const Tabloids = ({ data, location }: PageProps<TabloidSection>) => {
  const tabloidNbbUrl =
    data?.cmsUrlTabloidNbb?.sections?.[0]?.data?.urlTabloidNBB

  const tabloidNbbIsActive = !!(
    devFeatureToggle.useTabloidNbb && tabloidNbbUrl?.length
  )

  return (
    <NpmTabloidsPage
      cid={location?.search}
      tabloidNbbIsActive={tabloidNbbIsActive}
    />
  )
}

export const querySSG = graphql`
  query TabloidsPageQuery {
    cmsUrlTabloidNbb {
      sections {
        data
      }
    }
  }
`

export default Tabloids
